import { useEffect, useRef, useState } from "react";
import { useAppState } from "../../components/AppProvider/AppProvider";
import {
  fetchLocationCoordinates,
  fetchLocationRecordsHistoryRaw,
  fetchUsers,
} from "../../helpers/fetchers";
import {
  haversine,
  intlFormat,
  makeDateString,
  makeTimeString,
} from "../../helpers/helpers";
import { User } from "../../model";
import Select from "react-select";
import murmur from "murmurhash-js";

const AttendancePage = () => {
  const [ctx, dispatch] = useAppState() ?? [];
  const initState = () => {
    // fetchLocationRecordsRawData();
    fetchCoordinatesData();
  };

  const isLogin = useRef(false);

  const locRecords = useRef([] as any[]);
  const locRecordsFormer = useRef([] as any[]);

  const coordinates = useRef([] as any[]);

  const [, refresh] = useState(false);

  const render = () => {
    refresh((n) => !n);
  };

  const date = useRef(makeDateString(new Date()));
  const [users, setUsers] = useState<User[]>([]);
  const selectedUserFilter = useRef(null as null | User);

  const fetchData = async () => {
    const [users] = await Promise.all([
      fetchUsers(ctx?.baseUrl ?? "", ctx?.apiKey ?? "", {
        showHidden: true,
      }),
    ]);

    setUsers(users);
  };

  const fetchLocationRecordsRawData = async () => {
    const dat = await fetchLocationRecordsHistoryRaw(ctx?.apiKey ?? "", {
      from: new Date(
        `${makeDateString(new Date(date.current))}T00:00:00`
      ).toISOString(),
      days: 1,
    });

    locRecords.current = structuredClone(dat);

    render();

    locRecordsFormer.current = structuredClone(dat);
  };

  const fetchCoordinatesData = async () => {
    const dat = await fetchLocationCoordinates(ctx?.apiKey ?? "");

    coordinates.current = dat;

    render();
  };

  useEffect(() => {
    initState();
    fetchData();
  }, []);

  useEffect(() => {
    const pass = window.prompt("Enter password to continue:");
    if (!pass) {
      return;
    }

    if (pass !== "gspeauthenticat0r2021") {
      return;
    }

    isLogin.current = true;
    render();
  }, []);

  return (
    <>
      {!isLogin.current ? (
        <></>
      ) : (
        <>
          <div className="m-3">
            <div>
              <div>
                <h4>Attendance</h4>
              </div>
            </div>

            <div className="d-flex align-items-end justify-content-between">
              <div className="d-flex align-items-end">
                <div>
                  <div>
                    <small>
                      <strong>Date</strong>
                    </small>
                  </div>
                  <div
                    className="d-flex border border-secondary"
                    style={{ width: 200 }}
                  >
                    <input
                      className="form-control form-control-sm"
                      type="date"
                      defaultValue={date.current}
                      onBlur={(e) => {
                        date.current = e.target.value;

                        // fetchLocationRecordsRawData();

                        render();
                      }}
                    />
                  </div>
                </div>

                <div>
                  <div>
                    <small>
                      <strong>User</strong>
                    </small>
                  </div>

                  <div style={{ width: 300 }}>
                    <Select
                      placeholder="Filter User.."
                      options={users.map((u) => ({
                        label: `${u.name} (${u.username})`,
                        value: u,
                      }))}
                      onChange={(v) => {
                        const val = v as { value: User };

                        selectedUserFilter.current = val.value;
                        render();
                      }}
                    />
                  </div>
                </div>

                <div>
                  <button
                    className="btn btn-sm btn-success"
                    onClick={() => {
                      fetchLocationRecordsRawData();
                    }}
                  >
                    Get data
                  </button>
                </div>
                <div>
                  <button
                    className="btn btn-sm btn-outline-primary"
                    onClick={() => {
                      if (
                        locRecords.current.find(
                          (l) =>
                            `${l?.createdAt}` === `${new Date().toISOString()}`
                        )
                      ) {
                        return;
                      }
                      locRecords.current = [
                        ...locRecords.current,
                        {
                          createdAt: new Date().toISOString(),
                          updatedAt: new Date().toISOString(),
                          extUserId: selectedUserFilter.current?.id,
                        },
                      ];
                      render();
                    }}
                  >
                    Add
                  </button>
                </div>
              </div>

              <div>
                <button
                  onClick={async () => {
                    const filtered = locRecords.current
                      .filter((l) => {
                        if (selectedUserFilter.current) {
                          return (
                            `${l?.extUserId}` ===
                            `${selectedUserFilter.current.id}`
                          );
                        }

                        return true;
                      })
                      .filter((r) => {
                        const foundRecord = locRecordsFormer.current.find(
                          (rx) => `${rx.id}` === `${r.id}`
                        );

                        if (!foundRecord || !r?.id) {
                          console.log("NOTFOUND");
                          return true;
                        }

                        return (
                          murmur.murmur3(JSON.stringify(r)) !==
                          murmur.murmur3(JSON.stringify(foundRecord))
                        );
                      });

                    if (filtered.length === 0) {
                      alert("Nothing changed");
                      return;
                    }

                    // alert(filtered.length);
                    // return;

                    await Promise.all(
                      filtered.map(async (r) => {
                        try {
                          const resp = await fetch(
                            `${process.env.REACT_APP_LOCATION_URL}/locationrecords-save-direct`,
                            {
                              method: "post",
                              headers: { "content-type": "application/json" },
                              body: JSON.stringify(r),
                            }
                          );
                        } catch (e) {}
                      })
                    );

                    window.location.reload();
                  }}
                  className="btn btn-sm btn-primary"
                >
                  Save
                </button>
              </div>
            </div>

            <div
              className="overflow-auto border border-dark"
              style={{ resize: "vertical", height: "75vh" }}
            >
              <table
                className="table table-sm"
                style={{ borderCollapse: "separate" }}
              >
                <tr>
                  {[
                    "#",
                    "Name",
                    "Date",
                    "In/Out",
                    "Lat",
                    "Lon",
                    "Map",
                    "In range",
                  ].map((h) => {
                    return (
                      <>
                        <th
                          className="bg-dark text-light"
                          style={{ position: "sticky", top: 0 }}
                        >
                          {h}
                        </th>
                      </>
                    );
                  })}
                </tr>{" "}
                {(() => {
                  const lrs = [...locRecords.current];

                  lrs.sort(
                    (a, b) =>
                      new Date(a?.createdAt ?? "").getTime() -
                      new Date(b?.createdAt ?? "").getTime()
                  );

                  return lrs;
                })()
                  .filter((l) => {
                    if (selectedUserFilter.current) {
                      return (
                        `${l?.extUserId}` === `${selectedUserFilter.current.id}`
                      );
                    }

                    return true;
                  })
                  .map((r, i) => {
                    const foundCoord = coordinates.current.find((c) => {
                      return (
                        haversine(c?.lat, c?.lon, r?.lat, r?.lon) <=
                        c?.radiusMeters
                      );
                    });
                    return (
                      <>
                        <tr>
                          <td className="border border-dark">{i + 1}</td>
                          <td className="border border-dark">
                            {
                              users.find((u) => `${u.id}` === `${r?.extUserId}`)
                                ?.username
                            }
                          </td>
                          <td className="border border-dark">
                            <div className="d-flex">
                              <div>
                                {/* {intlFormat({
                              date: r?.createdAt,
                              dateStyle: "long",
                              timeStyle: "short",
                            })} */}
                              </div>
                              <div>
                                <div>
                                  <input
                                    key={`rec-${r?.id}`}
                                    type="datetime-local"
                                    defaultValue={`${makeDateString(
                                      new Date(r?.createdAt ?? "")
                                    )} ${makeTimeString(
                                      new Date(r?.createdAt ?? "")
                                    )}`}
                                    onBlur={(e) => {
                                      if (e.target.value === "") {
                                        return;
                                      }

                                      r.createdAt = new Date(
                                        e.target.value
                                      ).toISOString();
                                      render();
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="border border-dark">
                            <div className="d-flex">
                              <button
                                className={`btn btn-sm ${
                                  r.inOutType === 0
                                    ? `btn-primary`
                                    : `btn-outline-primary`
                                }`}
                                onClick={() => {
                                  locRecords.current = locRecords.current.map(
                                    (rx) =>
                                      `${rx?.createdAt}` === `${r?.createdAt}`
                                        ? { ...rx, inOutType: 0 }
                                        : rx
                                  );
                                  render();
                                }}
                              >
                                In
                              </button>
                              <button
                                className={`btn btn-sm ${
                                  r.inOutType === 1
                                    ? `btn-primary`
                                    : `btn-outline-primary`
                                }`}
                                onClick={() => {
                                  locRecords.current = locRecords.current.map(
                                    (rx) =>
                                      `${rx?.createdAt}` === `${r?.createdAt}`
                                        ? { ...rx, inOutType: 1 }
                                        : rx
                                  );
                                  render();
                                }}
                              >
                                Out
                              </button>
                            </div>
                          </td>

                          <td className="border border-dark">
                            <input
                              key={`${r?.createdAt}-lat`}
                              onBlur={(e) => {
                                locRecords.current = locRecords.current.map(
                                  (rx) =>
                                    `${rx?.createdAt}` === `${r?.createdAt}`
                                      ? {
                                          ...rx,
                                          lat: isNaN(parseFloat(e.target.value))
                                            ? 0
                                            : parseFloat(e.target.value),
                                        }
                                      : rx
                                );
                                render();
                              }}
                              defaultValue={r?.lat}
                            />
                          </td>
                          <td className="border border-dark">
                            <input
                              key={`${r?.createdAt}-lon`}
                              onBlur={(e) => {
                                locRecords.current = locRecords.current.map(
                                  (rx) =>
                                    `${rx?.createdAt}` === `${r?.createdAt}`
                                      ? {
                                          ...rx,
                                          lon: isNaN(parseFloat(e.target.value))
                                            ? 0
                                            : parseFloat(e.target.value),
                                        }
                                      : rx
                                );
                                render();
                              }}
                              defaultValue={r?.lon}
                            />
                          </td>
                          <td className="border border-dark">
                            <a href="">
                              <button className="btn btn-sm btn-primary px-1 py-0">
                                View Map
                              </button>
                            </a>
                          </td>
                          <td
                            className={`${
                              foundCoord
                                ? `bg-success text-light font-weight-bold`
                                : `bg-secondary`
                            }`}
                          >
                            {foundCoord?.name} (
                            {haversine(
                              foundCoord?.lat,
                              foundCoord?.lon,
                              r?.lat,
                              r?.lon
                            ).toFixed(1)}{" "}
                            m)
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </table>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AttendancePage;
